<template>
    <div class="homework_stu">
        <div class="ls_flexalign_center search_part">
            <el-date-picker
                v-model="params.dateRange"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                size="medium"
                style="width: 380px"
            ></el-date-picker>
            <el-select v-model="params.status" size="medium">
                <el-option
                    v-for="item in statusOptions"
                    :key="item.label"
                    :label="item.label"
                    :value="item.value"
                />
            </el-select>
            <el-input
                v-model.trim="params.schoolworkName"
                size="medium"
                placeholder="请输入作业名称"
            ></el-input>
            <el-button size="medium" plain @click="reLoadList()">
                查询
            </el-button>
        </div>
        <el-table
            :data="list"
            highlight-current-row
            :header-cell-style="{ background: '#FAFAFA' }"
            style="width: 100%"
        >
            <el-table-column label="作业名称" min-width="180">
                <template slot-scope="scope">
                    <span :title="scope.row.schoolworkName" class="written">
                        {{ scope.row.schoolworkName }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                prop="submitEndTime"
                label="要求完成时间"
            ></el-table-column>
            <el-table-column label="完成作业时间">
                <template slot-scope="scope">
                    {{ scope.row.submitTime || '--' }}
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <span :class="{ main_color: scope.row.status == 1 }">
                        {{ scope.row.status == 1 ? '未提交' : '已提交' }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="220px">
                <template slot-scope="scope">
                    <span
                        v-if="
                            (scope.row.status == 0 || scope.row.status == 3) &&
                            $store.state.info.memberInfo.studyStatus == 1
                        "
                    >
                        --
                    </span>
                    <div class="opt_part" v-else>
                        <span @click="gowork(scope.row)">
                            {{
                                scope.row.status == 1 ? '去做作业' : '查看作业'
                            }}
                        </span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align: center; margin-top: 15px">
            <el-pagination
                :current-page.sync="params.pageIndex"
                :page-size.sync="params.pageSize"
                :total="total"
                background
                layout="total,prev, pager, next"
                @current-change="getList"
            />
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
    data() {
        return {
            statusOptions: [
                { value: '', label: '全部' },
                { value: 1, label: '未提交' },
                { value: 2, label: '已提交' },
            ],
            params: {
                dateRange: [],

                status: '',
                schoolworkName: '', // 作业名称

                startTime: '',
                endTime: '',
                pageIndex: 1,
                pageSize: 10,
            },
            total: 0,
            list: [],
        };
    },
    async created() {
        this.getList();

        let nowTime = dayjs(new Date());
        this.params.dateRange = [
            nowTime.subtract(3, 'month').format('YYYY-MM-DD HH:mm:ss'),
            nowTime.format('YYYY-MM-DD HH:mm:ss'),
        ];
    },
    methods: {
        reLoadList() {
            this.params.pageIndex = 1;
            this.getList();
        },
        gowork(row) {
            if (row.status == 1) {
                // 去做作业
                if (!row.hasTimeout) {
                    if (new Date(row.submitEndTime) < Date.now()) {
                        this.$message.warning('已超过作业提交时间！');
                        return;
                    }
                }
                window.open(
                    this.$editAddr +
                        '?type=schoolwork&mode=edit&id=' +
                        row.id +
                        `&authToken=${localStorage.getItem('authToken')}`
                );
            } else {
                // 查看作业
                window.open(
                    this.$editAddr +
                        '?type=schoolwork&mode=examine&id=' +
                        row.id +
                        '&productId=' +
                        row.productId +
                        `&authToken=${localStorage.getItem('authToken')}`
                );
            }
        },
        getList() {
            this.$ajax
                .post('/schoolwork/student/queryList', {
                    ...this.params,
                    startTime:
                        this.params.dateRange && this.params.dateRange[0],
                    endTime: this.params.dateRange && this.params.dateRange[1],
                })
                .then(res => {
                    this.list = res.list;
                    this.total = res.total;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.homework_stu {
    padding: 20px 30px 0 0;
}

.search_part {
    margin-bottom: 15px;

    > * {
        + * {
            margin-left: 7px;
        }

        &:first-child {
            width: 120px;
        }

        &:nth-child(2) {
            width: 200px;
        }
    }
}
</style>
